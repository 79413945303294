import React from 'react';

import * as Icons from './Icons/Export';

interface IconsProps {
  name: IconTypes;
  style?: any;
  stroke?: string;
  fill?: string;
  type?: 'secondary' | 'primary';
  size?: 1 | 2 | 3 | 4 | 5 | 6;
  className?: string;
  alt: string;
}

export type IconTypes =
  | 'Profile'
  | 'Coins'
  | null;

const Icon = (props: IconsProps) => {
  const { name, stroke, type, size, fill, className, alt } = props;
  if (!name || name === null) {
    return <div />;
  }
  // @ts-ignore
  const Icon = Icons[name];

  return (
    <div className={`icons-${type}${size} z-50 ${className || ''}`}>
      <Icon stroke={stroke || '#6214bE'} fill={fill || '#6214bE'} alt={name + " " + "Icon"} {...props} />
    </div>
  );
};

export default Icon;
