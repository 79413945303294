import * as React from "react"

const Profile = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={52}
    height={57}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.909 5.453C34.082 2.578 30.095.994 25.445.994S16.807 2.578 13.98 5.453c-2.825 2.873-4.373 6.916-4.373 11.624 0 6.34 2.761 10.43 6.18 12.886.635.455 1.289.852 1.95 1.198A23.075 23.075 0 0 0 8.4 36.277C4.412 39.862 1.941 44.474.277 48.6a1.5 1.5 0 1 0 2.782 1.122c1.582-3.922 3.84-8.06 7.348-11.214 3.472-3.12 8.247-5.347 15.038-5.347 7.492 0 12.544 2.711 16.099 6.368 3.602 3.705 5.735 8.454 7.19 12.6.224.636-.25 1.365-1.02 1.365H9.674a1.5 1.5 0 0 0 0 3h38.04c2.74 0 4.791-2.68 3.852-5.358-1.506-4.288-3.815-9.526-7.871-13.698-2.66-2.736-6.041-4.983-10.36-6.225 1.744-.98 3.181-2.27 4.328-3.73 2.523-3.207 3.619-7.188 3.619-10.405 0-4.709-1.549-8.752-4.373-11.625Zm-2.14 2.103c2.194 2.232 3.513 5.48 3.513 9.522 0 2.616-.912 5.926-2.977 8.551-2.024 2.574-5.182 4.532-9.86 4.532-1.89 0-5.156-.66-7.907-2.635-2.674-1.92-4.93-5.122-4.93-10.448 0-4.042 1.318-7.29 3.512-9.522 2.192-2.229 5.372-3.562 9.325-3.562 3.952 0 7.133 1.333 9.325 3.562Z"
      fill="#00F200"
    />
  </svg>
)

export default Profile
