import React from 'react'
import IconCard from './cards/IconCard'
import { cardsDataType } from '../data/contact-us'

interface cardContainerType {
    data: cardsDataType
}

function IconCardsContainer({ data }:cardContainerType) {
    return (
        <div className="mt-50 container-padding lg:py-50 w-full text-center icon-cards-section">
            <div className="mx-auto grid grid-row-1 lg:grid-cols-3 icon-cards-section">
                {data.cards.map((card) => (
                    <IconCard key={card.title} data={card}/>
                ))}
            </div>
        </div>
    )
}

export default IconCardsContainer
