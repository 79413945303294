import React from 'react'
import { cardDataType } from '../../data/contact-us'
import Icon from '../Icon'

export interface cardType {
    data: cardDataType;
}

function IconCard({data}: cardType) {
    return (
        <div className='relative small-card p-10 pt-0 flex flex-1 flex-col justify-between border-primaryblack border border-b-4 mt-2 mb-6 lg:mb-0 text-left'>
            <div className='absolute overflow-hidden bg-white px-4 w-20 -top-6 lg:-top-9 xl:w-20 2xl:w-24 small-card-icon'>
                <Icon name={data.icon} stroke={'none'}/>
            </div>
            
            <div className="mt-14 icon-cards-content">
                <h4>{data.title}</h4>
                {data.content}
                <p className="icon-card-body"><strong>{data.phoneNumber}</strong></p>
                <p className="icon-card-legal-text">{data.callCentreHours}</p>
            </div>
            
        </div>
    )
}

export default IconCard
