import * as React from "react"

const Coins = (props) => (
  <svg
    width={45}
    height={55}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.417 3.5C11.71 3.5 3.5 10.356 3.5 19.5c0 10.254 8.261 16.952 18.917 16.952l.065.001c3.549.154 12.22-1.44 17.672-9.075a1.5 1.5 0 0 1 2.721.872c0 6.06-2.367 10.857-6.175 14.118-3.781 3.237-8.886 4.882-14.283 4.882-7.72 0-13.905-3.155-17.96-7.263 2.423 7.04 9.236 11.555 17.96 11.555 4.11 0 7.134-.699 9.62-2.014 2.493-1.318 4.568-3.321 6.68-6.136a1.5 1.5 0 1 1 2.4 1.8c-2.264 3.018-4.655 5.39-7.677 6.988-3.028 1.602-6.565 2.362-11.023 2.362C10.052 54.542.5 46.312.5 34.083a1.5 1.5 0 0 1 2.842-.67c2.673 5.345 9.457 10.837 19.075 10.837 4.81 0 9.185-1.467 12.331-4.161 2.12-1.816 3.728-4.224 4.54-7.22-5.954 5.476-13.355 6.727-16.903 6.583C10.502 39.438.5 31.787.5 19.5.5 8.227 10.57.5 22.417.5c11.846 0 21.916 7.727 21.916 19a1.5 1.5 0 1 1-3 0c0-9.144-8.211-16-18.916-16Z"
      fill="#00F200"
    />
  </svg>
)

export default Coins