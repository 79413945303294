import React, { ReactElement } from "react"

export type cardsDataType = {
    cards: cardDataType[]
}
export type cardDataType = {
    icon: any,
    title: string,
    content: ReactElement,
    phoneNumber: string,
    callCentreHours: string
}


export const cardData = {
    cards : [
        {   
            icon: 'Profile',
            title: "Existing customers",
            content: <p className="icon-card-body">If you’re already a <strong>Reviti</strong> customer and need to speak to someone about your policy, our insurance provider, Scottish Friendly, will take your call.</p>,
            phoneNumber: "0333 323 5433",
            callCentreHours: "Monday to Friday 8:30am - 5:30pm (excluding bank holidays)"
        },
        {   
            icon: 'Coins',
            title: "Life Insurance claims",
            content: <p>Scottish Friendly’s claims team is here for you.</p>,
            phoneNumber: "0333 323 5433",
            callCentreHours: "Monday to Friday 8:30am - 5:30pm (excluding bank holidays)"
        },
    ]
}