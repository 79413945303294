import * as React from "react";
import { FAQItem } from "../components/FAQItem";
import { Header } from "../components/Header";
import { Layout } from "../components/Layout";
import IconCardsContainer from "../components/IconCardsContainer";
import { cardData } from "../data/contact-us";


// markup
const IndexPage = () => {
  return (
    <Layout title={"Reviti - Contact us"}>
          <Header title={"So many ways to get in touch with us"} subtitle={"We are here to help and would love to hear from you. Feel free to send us a message or call us directly."} currentPage={"Contact us"}/>
          <IconCardsContainer data={cardData}/>
    </Layout>
  );
};

export default IndexPage;
